header {
  position: relative;
  padding: 12px;
}

.navbar {
  text-align: center;
  /* border: 1px solid rgb(87 149 174 / 30%); */
  /* box-shadow: 0 0 0.1em rgb(87 149 174); */
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar ul li {
  list-style-type: none;
  display: inline-block;
}

.navbar ul li {
  font-weight: 500;
  font-size: 16px;
  padding: 24px 6px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "Varela Round", sans-serif;
}
